<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="$router.push({ name: 'Widgets' })"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-clock
      </v-icon> Alert Responses
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="alertResponses"
        :search="search"
        class="elevation-1"
        :loading="updateProgress"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.app_user.first_name }} {{ item.app_user.last_name }}</td>
            <td>
              <font v-if="item.app_user.worker_category !== null">
                {{ item.app_user.worker_category.name }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.app_user.company !== null">
                {{ item.app_user.company.name }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.app_user.email }}</td>
            <td>{{ item.app_user.phone_number }}</td>
            <td>
              <font
                :color="responseStatusColor(item.response)"
                class="font-my-style"
              >
                {{ item.response | responseStatus }}
              </font>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';

  export default {
    name: 'EmergencyMessages',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      responseStatus (status) {
        if (status === 'unsafe') {
          return 'Unsafe';
        } else if (status === 'safe') {
          return 'Safe*';
        } else { return 'Waiting'; }
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'app_user.first_name' },
          { text: 'Category', value: 'worker_category' },
          { text: 'Company', value: 'name' },
          { text: 'Email', value: 'email' },
          { text: 'phone', value: 'phone_number' },
          { text: 'Response', value: 'response' },
        ],
        updateProgress: false,
        latestResponseStatuses: {},
      };
    },
    computed: {
      alertResponses () {
        return this.$store.getters['widgets/getAlertResponses'];
      },
      responseStatus () {
        return this.$store.getters['widgets/getResponseStatus'];
      },
      responseStatusLastUpdatedAt () {
        return this.$store.getters['widgets/getResponseLastUpdatedAt'];
      },
    },
    watch: {
      responseStatusLastUpdatedAt (val) {
        this.updateProgress = true;
        setTimeout(() => {
         this.latestResponseStatus();
         this.mergedAlertResponses();
        }, 1000);
      },
    },
    async mounted () {
      await this.getAlertResponses();
    },
    methods: {
      async getAlertResponses () {
        this.loading = true;
        const id = this.$route.params.alertId;
        await this.$store.dispatch('widgets/fetchAlertResponses', {
          alertId: id,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      responseStatusColor (status) {
        if (status === 'unsafe') {
          return '#C62828';
        } else if (status === 'safe') {
          return '#2E7D32';
        } else {
          return '#E65100';
        }
      },
      latestResponseStatus () {
        const responseStatuses = {};
        for (const response in this.responseStatus) {
          responseStatuses[response] = this.responseStatus[response][0];
        }
        this.latestResponseStatuses = responseStatuses;
      },
      mergedAlertResponses () {
        for (var alert of this.alertResponses) {
          if (alert.app_user.external_id in this.latestResponseStatuses) {
            alert.response = this.latestResponseStatuses[alert.app_user.external_id];
          }
        }
        this.updateProgress = false;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.success-style {
  color: #2E7D32;
  font-weight: bold;
  font-size: 12px;
}
.font-my-style {
  font-weight: bold;
}
.my-back-btn-style {
  margin-left: -10px !important;
  margin-bottom: 5px;
}
@media (max-width: 960px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
}
</style>
